<template>
  <div class="flex h-16 shrink-0 bg-primary w-full z-10">
    <div class="px-3 flex w-full items-center">
      <router-link class="text-white text-xl me-4" to="/" exact>对账单</router-link>

      <Nav>
        <NavList :preset="navbar">
          <NavItem value="inbox" as-child>
            <RLink to="/inbox"><i class="far fa-inbox-in fa-fw me-2"></i>渠道</RLink>
          </NavItem>
          <NavItem value="accounts" as-child>
            <RLink to="/accounts"><i class="far fa-coins fa-fw me-2"></i>账户</RLink>
          </NavItem>
          <NavItem value="projects" as-child>
            <RLink to="/projects"><i class="far fa-table-tree fa-fw me-2"></i>项目</RLink>
          </NavItem>
          <NavItem value="categories" as-child>
            <RLink to="/categories"><i class="far fa-folder-tree fa-fw me-2"></i>分类</RLink>
          </NavItem>
        </NavList>
      </Nav>

      <Nav class="ms-auto">
        <NavList :preset="navbar">
          <DropdownMenu>
            <DropdownMenuTrigger as-child>
              <NavItem value="account" v-if="session_data">
                {{ session_data.user_email }}<i class="fa-xs fa-solid fa-caret-down ms-1.5 -me-1"></i>
              </NavItem>
            </DropdownMenuTrigger>
            <DropdownMenuContent :align="'end'">
              <DropdownMenuItem>Action</DropdownMenuItem>
              <DropdownMenuSeparator />
              <DropdownMenuItem :class="{ 'disabled': sign_out_action.loading }" @click.prevent="sign_out_action.perform()">退出登录</DropdownMenuItem>
            </DropdownMenuContent>
          </DropdownMenu>
        </NavList>
      </Nav>
    </div>
  </div>
</template>

<script setup lang="ts">
import Loader from '$/lib/Loader'
import { useFrontline } from '$/lib/frontline'
import * as requests from "$/requests"
import { useSessionStore } from '$/store/session'
import { useRouter } from 'vue-router'
import { Nav, NavList, NavItem } from '$vendor/ui'
import RLink from './RLink.vue'
import { DropdownMenu, DropdownMenuContent, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuTrigger } from '$vendor/ui'
import type { NavPresenter } from '$vendor/ui/nav/types'

const session = useSessionStore()
const frontline = useFrontline()
const router = useRouter()

const session_data = session.data

const sign_out_action = Loader.build(async function() {
  await frontline.target(requests.session.Destroy).fire()
  session.data = null
  router.push('/')
})

const navbar = {
  list: () => 'flex',
  item: () => `
    text-white/50 px-2 py-2
    hover:text-white/80 hover:no-underline
    data-[state=active]:text-white data-[state=active]:border-border data-[state=active]:border-b-white
  `,
} satisfies NavPresenter
</script>
