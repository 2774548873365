import { Type } from '@yoolabs/class-transformer'
import { TransactionRecordConnection } from './TransactionRecordConnection'

export class EntryHead {
  id!: number
  title!: string
  amount!: string
  currency!: string
  occurred_at!: string
  uid!: string
  store!: string
  state!: string
  state_text!: string
  remark!: string
  color_hex!: string
  layer!: number
  source_id!: number
  source_type!: string

  get owner_path() {
    return this.source_type == "Bill" ? "bills" : "messages"
  }
}

export class Entry extends EntryHead {
  row_id!: string

  @Type(() => EntryHead)
  leader_head!: EntryHead

  @Type(() => EntryHead)
  follower_heads!: EntryHead[]

  source_channel!: string
  source_title!: string
}

export class EntryInfo extends Entry {
  @Type(() => TransactionRecordConnection)
  transaction_record_connections!: TransactionRecordConnection[]
}
