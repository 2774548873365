import { Battlefield } from "$/lib/frontline"
import { type App } from "vue"


function install(app: App) {
  const axios = app.config.globalProperties.$axios
  const keyv = app.config.globalProperties.$keyv

  const battlefield = new Battlefield()
  battlefield.weapons = axios
  battlefield.supplies = keyv
  battlefield.rotation()

  app.config.globalProperties.$battlefield = battlefield
}

export default function(app: App) {
  install(app)

  const router = app.config.globalProperties.$router
  router.beforeResolve((to, from) => {
    const battlefield = app.config.globalProperties.$battlefield
    battlefield.rotation()
  })
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $battlefield: Battlefield
  }
}
