import Axios from 'axios'
import { type App } from 'vue'

const axios = Axios.create({
  baseURL: '/api/',
  timeout: 10 * 1000,
})

export default function(app: App) {
  app.config.globalProperties.$axios = axios
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $axios: typeof axios
  }
}
