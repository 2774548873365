import mitt from 'mitt'
import { type App } from "vue"

const emitter = mitt()

export default function(app: App) {
  app.config.globalProperties.$emitter = emitter
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $emitter: typeof emitter
  }
}
