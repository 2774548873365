<template>
  <RouterView v-slot="{ Component, route }">
    <div class="flex flex-col h-screen" :key="route.fullPath">
      <Suspense timeout="0">
        <AppNavbar />
      </Suspense>

      <ErrorPage v-if="error" v-bind="{ error }" />

      <Transition name="fade">
        <Suspense timeout="0" v-show="!error">
          <component :is="Component" />
        </Suspense>
      </Transition>
    </div>
  </RouterView>
</template>

<script setup lang="ts">
import ErrorPage from '$/pages/error.vue'
import { CanceledError } from 'axios'
import { onErrorCaptured, ref } from 'vue'
import { onBeforeRouteLeave } from 'vue-router'
import AppNavbar from '$/components/AppNavbar.vue'

const error = ref(null! as any)

onBeforeRouteLeave(() => {
  console.log("onBeforeRouteLeave")
  error.value = null
})

// onErrorCaptured((err) => {
//   console.log("onErrorCaptured")
//   if (err instanceof CanceledError) {
//     return false
//   }

//   if (!error.value) {
//     error.value = err
//     console.error(err)
//   }

//   return false
// })
</script>
