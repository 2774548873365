import Keyv from '@keyvhq/core'
import type { App } from 'vue'

const keyv = new Keyv()

export default function(app: App) {
  app.config.globalProperties.$keyv = keyv
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $keyv: Keyv
  }
}
