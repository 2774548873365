import { reactive } from "vue"

export default class Loader<T extends any[], R> {
  action: (...args: T) => R
  loading = false

  constructor(action: (...args: T) => R) {
    this.action = action
  }

  async perform(...args: T) {
    this.loading = true
    try {
      await this.action(...args)
    } finally {
      this.loading = false
    }
  }

  static build<T extends any[], R>(action: (...args: T) => R) {
    const loader = new Loader(action)
    return reactive(loader)
  }
}
