import { type App } from 'vue'
import { createPinia } from 'pinia'

const pinia = createPinia()

export default function(app: App) {
  app.config.globalProperties.$pinia = pinia
  app.use(pinia)
}

declare module 'vue' {
  interface ComponentCustomProperties {
    $pinia: typeof pinia
  }
}
