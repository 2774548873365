import { useFrontline } from '$/lib/frontline'
import type { Session } from '$/models'
import * as requests from "$/requests"
import { defineStore, getActivePinia } from 'pinia'
import { ref, type App } from 'vue'

export const useSessionStore = defineStore('session', () => {
  const app = (getActivePinia() as any)._a as App
  const battlefield = app.config.globalProperties.$battlefield
  const frontline = useFrontline(battlefield)

  const data = ref(undefined! as null | Session)

  async function prepare() {
    if (data.value !== undefined) {
      return data
    }

    data.value = await frontline.target(requests.session.Get).fire()
    return data.value
  }


  return { data, prepare }
})
