import { useFrontline } from '$/lib/frontline'
import type BaseRequest from '$/requests/BaseRequest'
import { defineStore, getActivePinia } from 'pinia'
import { reactive, ref, type App } from 'vue'

export const usePageStore = defineStore('page', () => {
  const app = (getActivePinia() as any)._a as App
  const battlefield = app.config.globalProperties.$battlefield
  const frontline = useFrontline(battlefield)

  const emitter = app.config.globalProperties.$emitter

  const cache_frame_sizes = ref(new Map<string, { width: number, height: number }>)

  const globals = reactive(new Map<any, BaseRequest<any>>())

  function global<T extends BaseRequest<any>>(klass: new () => T): T {
    if (globals.has(klass)) {
      return globals.get(klass) as T
    }

    const troop = frontline.target(klass)
    globals.set(klass, troop)
    return troop
  }

  function clearFor(klass: new () => BaseRequest<any>) {
    globals.delete(klass)
  }

  function clear() {
    globals.clear()
  }

  return { emitter, cache_frame_sizes, global, clear, clearFor }
})

