import "./assets/style.scss"
import "./widgets/flatpickr"

import "$vendor/fontawesome"
import "$vendor/ui/main.css"

import { createApp } from 'vue'
import App from './App.vue'
const app = createApp(App)

import * as initializers from "./initializers"
for (const key in initializers) {
  initializers[key as keyof typeof initializers](app)
}

app.mount("#app")
