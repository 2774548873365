import { usePageStore } from "$/store/page"
import { useSessionStore } from "$/store/session"
import type { App } from "vue"

export default function(app: App) {
  const router = app.config.globalProperties.$router
  const pinia = app.config.globalProperties.$pinia

  router.beforeEach(async (to, from) => {
    const page = usePageStore(pinia)
    page.clear()
  })
}
